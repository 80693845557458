import { NgModule } from '@angular/core';
import { AngularHighchartsChartComponent } from './angular-highcharts-chart.component';

@NgModule({
  imports: [
  ],
  declarations: [AngularHighchartsChartComponent],
  exports: [AngularHighchartsChartComponent]
})
export class AngularHighchartsChartModule { }
